import React, {
  useEffect,
  useRef,
  useState,
  memo,
  type Dispatch,
  type SetStateAction,
  type InputHTMLAttributes,
  type ChangeEvent,
  type FocusEvent,
  type ReactNode,
} from "react";
import { Text, Box, type FontSizeType } from "@cruk/cruk-react-components";

import { validate, calcLength } from "@fwa/src/utils/formUtils";

import { TextAreaStyled } from "@fwa/src/components/EditableTextAreaField/styles";
import { type ValidationType } from "@fwa/src/types";

type Props = InputHTMLAttributes<HTMLInputElement> & {
  setCurrentValue: (value: string) => void;
  text: string;
  validation: ValidationType;
  setValidationMessage: Dispatch<SetStateAction<string>>;
  label?: string;
  hintText?: ReactNode;
  validationMessage?: string;
  textSize?: FontSizeType;
  lineCount?: number;
};

// This component is more streamlined version the EditableText component
// It is based on a TextAreaField, which is a standard text textArea element
// so doesn't need any of the complexity of selecting ranges
// or updating inner html to keep new lines consistent across different browsers
export const EditableTextAreaField = ({
  setCurrentValue,
  text,
  validation,
  setValidationMessage,
  validationMessage = "",
  label = "",
  hintText,
  textSize,
  lineCount = 3,
  "aria-label": ariaLabel,
}: Props) => {
  const [charCount, setCharCount] = useState(calcLength(text || ""));
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
    validate(e.target.value, validation, setValidationMessage);
  };

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCharCount(calcLength(e.target.value || text || ""));
    setCurrentValue(e.target.value);
  };

  // reset validation message on first render
  useEffect(() => {
    setValidationMessage("");
  }, [setValidationMessage]);

  useEffect(() => {
    if (text === "" && textAreaRef.current) {
      textAreaRef.current.value = "";
    }
  }, [text, textAreaRef]);

  return (
    <>
      <TextAreaStyled
        ref={textAreaRef}
        label={label}
        aria-label={ariaLabel}
        onBlur={handleBlur}
        onChange={handleChange}
        defaultValue={text}
        errorMessage={validationMessage}
        hintText={hintText}
        maxLength={validation?.maxLength || undefined}
        $textSize={textSize || "m"}
        $lineCount={lineCount}
      />

      {validation.maxLength && validation.maxLength > 0 && (
        <Box marginBottom="s">
          <Text textAlign="right" marginTop="xxs">{`${
            validation.maxLength - charCount
          } characters remaining`}</Text>
        </Box>
      )}
    </>
  );
};

export default memo(EditableTextAreaField);
