import React, {
  useState,
  useEffect,
  type ChangeEvent,
  type FocusEvent,
  type Dispatch,
  type SetStateAction,
  type InputHTMLAttributes,
  type ReactNode,
  memo,
  useRef,
} from "react";
import { Text, type FontSizeType } from "@cruk/cruk-react-components";

import { validate, calcLength } from "@fwa/src/utils/formUtils";

import { TextFieldStyled } from "@fwa/src/components/EditableTextField/styles";
import { type ValidationType } from "@fwa/src/types";

type Props = InputHTMLAttributes<HTMLInputElement> & {
  setCurrentValue: (value: string) => void;
  text: string;
  validation: ValidationType;
  setValidationMessage: Dispatch<SetStateAction<string>>;
  label?: string;
  hintText?: ReactNode;
  extraBottom?: ReactNode;
  extraLeft?: ReactNode;
  extraRight?: ReactNode;
  extraTop?: ReactNode;
  validationMessage?: string;
  textSize?: FontSizeType;
  errorMessage?: string;
  inputMode?: string;
  pattern?: string;
  name?: string;
};

// This component is more streamlined version the EditableText component
// It is based on a TextField, which is a standard text input element
// so doesn't need any of the complexity of selecting ranges
// or updating inner html to keep new lines consistent across different browsers
export const EditableTextField = ({
  setCurrentValue,
  text,
  validation,
  setValidationMessage,
  validationMessage = "",
  label = "",
  hintText,
  extraBottom,
  extraLeft,
  extraRight,
  extraTop,
  textSize,
  errorMessage,
  required,
  inputMode,
  pattern,
  name,
  "aria-label": ariaLabel,
}: Props) => {
  const [charCount, setCharCount] = useState(calcLength(text || ""));
  const textRef = useRef<HTMLInputElement>(null);

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    validate(e.target.value, validation, setValidationMessage);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    setCharCount(calcLength(input.value));
    setCurrentValue(e.target.value);
  };

  // reset validation message on first render
  useEffect(() => {
    setValidationMessage("");
  }, [setValidationMessage]);

  useEffect(() => {
    if (text === "" && textRef.current) {
      textRef.current.value = "";
    }
  }, [text, textRef]);

  return (
    <>
      <TextFieldStyled
        name={name}
        ref={textRef}
        label={label}
        aria-label={ariaLabel}
        defaultValue={text}
        onBlur={handleBlur}
        onChange={handleChange}
        errorMessage={validationMessage || errorMessage}
        hintText={hintText}
        extraBottom={extraBottom}
        extraLeft={extraLeft}
        extraRight={extraRight}
        extraTop={extraTop}
        maxLength={validation?.maxLength || undefined}
        required={required}
        inputMode={inputMode}
        pattern={pattern}
        $textSize={textSize || "m"}
      />

      {validation.maxLength && validation.maxLength > 0 && (
        <Text textAlign="right" marginTop="xxs">{`${
          validation.maxLength - charCount
        } characters remaining`}</Text>
      )}
    </>
  );
};

export default memo(EditableTextField);
