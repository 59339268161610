import { useState, useEffect, type ReactNode, useRef } from "react";
import { useSWRConfig } from "swr";
import {
  UserBlock,
  Text,
  IconFa,
  ErrorText,
  Box,
  Collapse,
} from "@cruk/cruk-react-components";
import { useRouter } from "next/compat/router";
import { formatDistanceToNow, parseISO } from "date-fns";
import { faClock } from "@fortawesome/free-regular-svg-icons";

import { useTracking } from "@fwa/src/hooks/useTracking";
import { isBrowser } from "@fwa/src/utils/browserUtils";
import { isToday, isTomorrow, isValidDate } from "@fwa/src/utils/timeUtils";
import { useKey } from "@fwa/src/hooks/useKey";
import { fetcher } from "@fwa/src/services/apiClient";
import {
  fwsUrlNewFakeDonation,
  fwsUrlDonation,
} from "@fwa/src/services/donationService";
import { buildQuery, queryAsString } from "@fwa/src/utils/urlUtils";
import { numberWithCommas } from "@fwa/src/utils/formatUtils";
import { useOptimizelyContext } from "@fwa/src/contexts/OptimizelyContext";

import { DateRangePicker } from "@fwa/src/components/DateRangePicker";
import { FormActions } from "@fwa/src/components/FormActions";
import { EditableTextField } from "@fwa/src/components/EditableTextField";
import { EditableTextAreaField } from "@fwa/src/components/EditableTextAreaField";
import { ReadMore } from "@fwa/src/components/ReadMore";

import { RowCenter } from "@fwa/src/components/styles";

import { MoneyText } from "@fwa/src/components/FeedItemDonationForm/styles";

import {
  type FeedItemDonationRealType,
  type FeedItemDonationFakeType,
  type FeedItemStatusType,
  type FeedItemDonationFacebookType,
  type FundraisingPageType,
} from "@fwa/src/types";
import { useFundraiserContext } from "@fwa/src/contexts/FundraiserContext";

const facebookAvatar = "/assets/images/img/facebook-circle.png";

type Props = {
  feedItem?:
    | FeedItemDonationRealType
    | FeedItemDonationFakeType
    | FeedItemDonationFacebookType;
  handleCancelClick?: () => void;
  handleEditData?: (data: FeedItemDonationFakeType) => void;
  handleCreate?: (data: FeedItemDonationFakeType) => void;
  pageUrl: string;
  pageId: string;
  avatarUrl?: string;
  online?: boolean;
  status: FeedItemStatusType;
  parentPageLink?: ReactNode;
  secret?: string;
};

export const FeedItemDonationForm = ({
  feedItem,
  avatarUrl,
  handleCancelClick,
  handleEditData,
  handleCreate,
  pageUrl,
  pageId,
  status,
  parentPageLink,
  secret,
}: Props) => {
  const [fundraiserState] = useFundraiserContext();
  const { fundraiser } = fundraiserState;
  const { cache, mutate: globalMutate } = useSWRConfig();
  const { trackError, trackEventGtm } = useTracking();
  const { trackEventOptimizely, optimizelyContext } = useOptimizelyContext();
  const { optimizelyUserContext } = optimizelyContext;
  const nameRef = useRef<HTMLSpanElement | null>(null);
  const amountRef = useRef<HTMLSpanElement | null>(null);
  const bodyRef = useRef<HTMLSpanElement | null>(null);
  const dateRef = useRef<HTMLSpanElement | null>(null);
  const online = feedItem && "online" in feedItem ? feedItem.online : true;
  const router = useRouter();

  const [donationMessageState, setDonationMessageState] = useState(
    feedItem?.donationMessage || "",
  );
  const [amountState, setAmountState] = useState(feedItem?.amount || 0);
  const [date, setDate] = useState<Date | undefined>(
    feedItem?.created ? new Date(feedItem?.created) : undefined,
  );
  const [validationMessageName, setValidationMessageName] =
    useState<string>("");
  const [validationMessageBody, setValidationMessageBody] =
    useState<string>("");
  const [validationMessageAmount, setValidationMessageAmount] =
    useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDateValid, setIsDateValid] = useState<boolean>(true);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const isPageOwner =
    fundraiser?.uniqueId &&
    fundraiser?.uniqueId === feedItem?.page?.fundraiser?.uniqueId;

  const [donationNameState, setDonationNameState] = useState(
    feedItem?.donationName || "",
  );

  const makeTimeString = (dateValue?: Date): string =>
    dateValue
      ? formatDistanceToNow(new Date(dateValue), {
          addSuffix: true,
          includeSeconds: true,
        })
      : `Right now`;

  const feedItemTimeString = makeTimeString(
    feedItem?.created ? new Date(feedItem?.created) : undefined,
  );

  const inEditTimeString = makeTimeString(date);

  const isInvalid =
    (showDatePicker && !isDateValid) ||
    !!validationMessageName.length ||
    !!validationMessageBody.length ||
    !!validationMessageAmount.length;

  // This is only visible to page owners
  const donorFullName = !isPageOwner
    ? null
    : feedItem && "donorName" in feedItem
      ? feedItem.donorName
      : null;

  const clearFields = () => {
    setDonationNameState("");
    setDonationMessageState("");
    setAmountState(0);
    setDate(feedItem?.created ? new Date(feedItem?.created) : new Date());
  };

  const handleDonationNameChange = (name: string) => {
    setDonationNameState(name);
  };

  const handleDateChange = (dateToChange: Date) => {
    const sanitisedDate = isValidDate(dateToChange) ? dateToChange : new Date();
    const startOfDay: Date =
      isToday(sanitisedDate) || isTomorrow(sanitisedDate)
        ? sanitisedDate
        : new Date(sanitisedDate.setHours(0, 0, 0, 0));

    setDate(startOfDay);
  };

  const getDonationFormData: () => Partial<
    FeedItemDonationFakeType | FeedItemDonationRealType
  > = () => {
    if (feedItem?.entityType === "DonationReal") {
      const data: Partial<FeedItemDonationRealType> = {
        donationName: donationNameState,
        donationMessage: donationMessageState || "",
      };
      return data;
    }
    // DonationFake and no entity type if creating donation.
    const data: Partial<FeedItemDonationFakeType> = {
      amount: Number(amountState),
      created:
        date && showDatePicker
          ? `${
              isToday(date)
                ? new Date().toISOString().split(".")[0]
                : date.toISOString().split(".")[0]
            }+00:00`
          : undefined,
      donationName: donationNameState,
      donationMessage: donationMessageState || "",
      online: false,
      toBeGiftAided: false,
    };
    return data;
  };

  const handleInvalid = () => {
    if (validationMessageName.length && nameRef.current) {
      nameRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }

    if (showDatePicker && !isDateValid && dateRef.current) {
      dateRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }

    if (validationMessageAmount.length && amountRef.current) {
      amountRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      return;
    }

    if (validationMessageBody.length && bodyRef.current) {
      bodyRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const doCreate = async (): Promise<boolean> =>
    fetcher(fwsUrlNewFakeDonation({ pageId }), {
      method: "POST",
      body: JSON.stringify(getDonationFormData()),
    })
      .then((res) => res as FeedItemDonationFakeType)
      .then((donation: FeedItemDonationFakeType) => {
        setShowDatePicker(false);
        // mutate feed items cache
        if (!donation.online) {
          trackEventGtm({
            event: "offline_donation",
            amount: donation.amount,
          });
        }
        if (handleCreate) {
          handleCreate(donation);
        }
        return true;
      });

  const doEdit = async (): Promise<boolean> => {
    const { transaction_id: transactionId, signature } = router?.query || {};
    // We are checking for the two ways of being able to edit a real donation
    // secret is used in confirmation email and transaction_id & signature
    // on the confirmation page.
    const query = secret
      ? buildQuery({ secret })
      : transactionId && signature
        ? buildQuery({
            transaction_id: queryAsString(transactionId),
            signature: queryAsString(signature),
          })
        : "";

    return fetcher(
      `${fwsUrlDonation({ donationId: feedItem?.uniqueId || "" })}${query}`,
      {
        method: "PATCH",
        body: JSON.stringify(getDonationFormData()),
      },
    )
      .then((res) => res as FeedItemDonationFakeType)
      .then((donation: FeedItemDonationFakeType) => {
        // mutate feed items cache
        if (handleEditData) {
          handleEditData(donation);
        }
        return true;
      });
  };

  const handleSubmitClick = () => {
    if (!amountState) {
      setValidationMessageAmount("this field is required");
      return undefined;
    }

    handleSubmit().catch((err) => {
      trackError(err as Error, { component: "DonationForm" });
    });
    return undefined;
  };
  const handleSubmit = async () => {
    setErrorMessage("");
    setIsLoading(true);

    const doSubmit = status === "creating" ? doCreate : doEdit;

    const submitSuccess: boolean | void = await doSubmit().catch((err) => {
      trackError(err as Error, { component: "DonationForm" });
      setIsLoading(false);
      setErrorMessage("Unable to submit");
    });

    if (!submitSuccess) {
      setIsLoading(false);
      setErrorMessage("Unable to submit");
    }

    setIsLoading(false);
    clearFields();

    const pageCache = cache.get(pageUrl)?.data as FundraisingPageType;
    //  do mutation for page data, setting totals
    if (pageCache) {
      const difference = amountState - (feedItem?.amount || 0);
      const newPageData = {
        ...pageCache,
        donationsTotalAmountFake:
          pageCache.donationsTotalAmountFake + difference,
        donationsTotalAmount: pageCache.donationsTotalAmount + difference,
      };
      globalMutate(pageUrl, newPageData, false).catch((err) => {
        trackError(err as Error, { component: "DonationForm" });
      });
    }

    return undefined;
  };

  // Tracks donation
  useEffect(() => {
    if (isBrowser && window.location.search.includes("seller_reference=")) {
      const searchParams = new URLSearchParams(window.location.search);
      const sellerReference = searchParams.get("seller_reference");
      try {
        if (
          // makes sure if page is refreshed we are not tracking donation twice
          localStorage.getItem(`confirmation_${sellerReference || ""}`) === null
        ) {
          trackEventOptimizely({
            eventKey: "donation",
            eventTags: {
              revenue: (feedItem?.amount || 0) * 100, // reserved tag
              value: feedItem?.amount || 0,
            },
          });

          localStorage.setItem(`confirmation_${sellerReference || ""}`, "true");
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        // Private browsing mode
      }
    }
  }, [feedItem, trackEventOptimizely]);

  // Tracks owner donation only when we have fundraiser details in optimizely user context
  useEffect(() => {
    if (
      !fundraiser ||
      !isPageOwner ||
      !trackEventOptimizely ||
      !feedItem.amount ||
      !optimizelyUserContext?.getUserId() ||
      !isBrowser
    )
      return;

    if (window.location.search.includes("seller_reference=")) {
      const searchParams = new URLSearchParams(window.location.search);
      const sellerReference = searchParams.get("seller_reference");

      try {
        if (
          localStorage.getItem(
            `confirmation_owner_${sellerReference || ""}`,
          ) === null &&
          isPageOwner &&
          fundraiser?.uniqueId
        ) {
          trackEventOptimizely({
            eventKey: "donation-owner",
            eventTags: {
              revenue: (feedItem?.amount || 0) * 100, // reserved tag
              value: feedItem?.amount || 0,
            },
          });
          localStorage.setItem(
            `confirmation_owner_${sellerReference || ""}`,
            "true",
          );
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (e) {
        // Private browsing mode
      }
    }
  }, [
    optimizelyUserContext,
    fundraiser,
    isPageOwner,
    trackEventOptimizely,
    feedItem,
  ]);

  // cancel on escape key
  useKey(
    () => {
      if (handleCancelClick) handleCancelClick();
    },
    {
      detectKeys: ["Escape"],
    },
    [],
  );

  return status === "creating" || status === "editing" ? (
    <form noValidate data-component="feed-item-donate-form-offline">
      {/* Userblock section */}

      <span ref={nameRef}>
        <EditableTextField
          label="Donor name"
          text={donationNameState.replace(/ *\([^)]*\) */g, "")}
          validation={{
            type: "textField",
            maxLength: 101,
          }}
          setValidationMessage={setValidationMessageName}
          validationMessage={validationMessageName}
          setCurrentValue={(value: string | number) => {
            handleDonationNameChange(value as string);
          }}
          textSize="l"
        />
      </span>

      <Box>
        {parentPageLink ? <Text>Donated to {parentPageLink}</Text> : null}
        <Text as="span" textColor="currentColor">
          <IconFa faIcon={faClock} />
          {` ${showDatePicker ? inEditTimeString : feedItemTimeString}`}
        </Text>
      </Box>

      {/* 
            Edit date and Money section, this is only visible for creating and editing offline donations,
            when reacting a donation it might not have an entity type yet. The donations you can create are fake/offline donations 
          */}
      {!feedItem?.entityType || feedItem?.entityType === "DonationFake" ? (
        <>
          <Box marginBottom="xs">
            <Collapse
              id="toggle-date-field-visible"
              onOpenChange={(isOpen) => {
                setShowDatePicker(isOpen);
              }}
              startOpen={showDatePicker}
              headerTitleText={`${
                showDatePicker
                  ? status === "creating" ||
                    feedItemTimeString === "Right now" ||
                    (date && isToday(date))
                    ? "Set date to today"
                    : "Revert donation date"
                  : "Change donation date"
              }`}
            >
              <span ref={dateRef}>
                <DateRangePicker
                  allowNullDate
                  isDateRange={false}
                  startDate={date}
                  startDateLabel="Donation date"
                  maxDate={new Date(new Date().setHours(23, 59, 59, 999))}
                  minDate={parseISO("1753-01-01")}
                  setCurrentValue={(selection) => {
                    handleDateChange(selection as Date);
                  }}
                  setIsDateRangeValid={(isValidDateRange: boolean) => {
                    setIsDateValid(isValidDateRange);
                  }}
                />
              </span>
            </Collapse>
          </Box>

          <Box marginBottom="none">
            <span ref={amountRef}>
              <EditableTextField
                required
                label="Donation amount"
                inputMode="decimal"
                pattern="/[0-9.]*/"
                text={
                  amountState ? numberWithCommas(amountState.toFixed(2)) : ""
                }
                setCurrentValue={(value) => {
                  setAmountState(parseFloat(`${value || 0}`));
                }}
                validation={{
                  required: true,
                  type: "money",
                  minValue: 2,
                  maxValue: 1000000,
                }}
                setValidationMessage={setValidationMessageAmount}
                validationMessage={validationMessageAmount}
                extraLeft="£"
              />
            </span>
          </Box>
        </>
      ) : (
        <RowCenter>
          <MoneyText textSize="xxl">{`£${
            feedItem?.amount
              ? numberWithCommas(feedItem?.amount.toFixed(2))
              : "0.00"
          }`}</MoneyText>
        </RowCenter>
      )}

      {(feedItem?.giftAidAmount || 0) > 0 && (
        <Text textSize="m" textAlign="center">
          {`+£${
            feedItem?.giftAidAmount
              ? numberWithCommas(feedItem?.giftAidAmount.toFixed(2))
              : "0.00"
          } Gift Aid`}
        </Text>
      )}

      {/* Text body section */}

      <Box marginTop="xs">
        <span ref={bodyRef}>
          <EditableTextAreaField
            label="Donation message"
            text={donationMessageState}
            setCurrentValue={(value) => {
              setDonationMessageState(value);
            }}
            validation={{
              type: "textField",
              maxLength: 150,
            }}
            setValidationMessage={setValidationMessageBody}
            validationMessage={validationMessageBody}
            lineCount={4}
          />
        </span>
      </Box>
      <div>
        {errorMessage ? <ErrorText>{errorMessage}</ErrorText> : null}
        <FormActions
          isLoading={isLoading}
          isValid={!isInvalid}
          onCancel={handleCancelClick || null}
          onSubmit={handleSubmitClick}
          onInvalid={handleInvalid}
          submitButtonType="button"
        />
      </div>
    </form>
  ) : (
    <>
      {/* View only */}

      {/* Userblock section */}
      <Box marginBottom="xs">
        <UserBlock
          size="m"
          name={
            feedItem?.entityType === "DonationFacebook"
              ? "Facebook donation"
              : `${feedItem?.donationName || "Anonymous"}${
                  !!donorFullName && donorFullName !== feedItem?.donationName
                    ? ` (${donorFullName})`
                    : ""
                }`
          }
          avatarName={
            donationNameState.length ? donationNameState : "Anonymous"
          }
          avatarUrl={
            feedItem?.entityType === "DonationFacebook"
              ? facebookAvatar
              : avatarUrl
          }
          extra={
            <>
              {parentPageLink && <Text>Donated to {parentPageLink}</Text>}
              <Text as="span" textColor="currentColor">
                <IconFa faIcon={faClock} />
                {` ${feedItemTimeString}`}
              </Text>
            </>
          }
        />
      </Box>

      {/* Money section */}
      <RowCenter>
        <MoneyText textSize="xxl">{`£${
          feedItem?.amount
            ? numberWithCommas(feedItem?.amount.toFixed(2))
            : "0.00"
        }`}</MoneyText>
      </RowCenter>

      {(feedItem?.giftAidAmount || 0) > 0 && (
        <Text textSize="m" textAlign="center">
          {`+£${
            feedItem?.giftAidAmount
              ? numberWithCommas(feedItem?.giftAidAmount.toFixed(2))
              : "0.00"
          } Gift Aid`}
        </Text>
      )}
      {!feedItem?.entityType ||
      (feedItem?.entityType === "DonationFake" && !online) ? (
        <Text textAlign="center" marginBottom="s">
          Offline donation
        </Text>
      ) : null}
      {/* Text body section */}
      <ReadMore>{feedItem?.donationMessage}</ReadMore>
    </>
  );
};

export default FeedItemDonationForm;
