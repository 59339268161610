import { type KeyboardEvent } from "react";
import { GalleryThumbnail } from "@fwa/src/components/GalleryThumbnail";

import { type GalleryImageType } from "@fwa/src/types";

type Props = {
  galleryImage: GalleryImageType;
  onClick: (selectedImageIndex: number) => void;
};

export const GalleryImageClickable = ({ galleryImage, onClick }: Props) => (
  <div
    data-cta-type="gallery-image-select"
    style={{ cursor: "pointer" }}
    role="button"
    tabIndex={0}
    aria-label={`open image carousel at position ${galleryImage.weight}`}
    onClick={() => {
      onClick(galleryImage.weight);
    }}
    onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
      const key = e.code;
      if (key === "Enter") {
        e.preventDefault();
        onClick(galleryImage.weight);
      }
    }}
  >
    <GalleryThumbnail image={galleryImage.image} />
  </div>
);

export default GalleryImageClickable;
