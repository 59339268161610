import { useState, type ReactNode, memo } from "react";
import dynamic from "next/dynamic";
import {
  UserBlock,
  Text,
  Modal,
  IconFa,
  Button,
  Box,
  ErrorText,
} from "@cruk/cruk-react-components";
import { formatDistanceToNow } from "date-fns";
import { faClock, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faCamera, faRotateLeft } from "@fortawesome/free-solid-svg-icons";

import { useTracking } from "@fwa/src/hooks/useTracking";

import { useKey } from "@fwa/src/hooks/useKey";
import {
  imagePathFromImageType,
  updateImage,
} from "@fwa/src/services/imageService";
import {
  fwsUrlNewPostWithImage,
  fwsUrlNewPost,
  fwsUrlPost,
  fwsUrlPostImage,
  fwsUrlGalleryImage,
} from "@fwa/src/services/fundraisingPageService";
import { fetcher } from "@fwa/src/services/apiClient";

import { EditableTextAreaField } from "@fwa/src/components/EditableTextAreaField";
import { FormActions } from "@fwa/src/components/FormActions";
import { ImageUploader } from "@fwa/src/components/ImageUploader";
import { ReadMore } from "@fwa/src/components/ReadMore";
import { GalleryThumbnail } from "@fwa/src/components/GalleryThumbnail";
import { GalleryImageClickable } from "@fwa/src/components/GalleryImageClickable";

import { RowStretch } from "@fwa/src/components/styles";
import {
  SpanVerticalSpacer,
  ImageWrapper,
  UploadedImageWrapper,
} from "@fwa/src/components/FeedItemPostForm/styles";

import {
  type FeedItemStatusType,
  type RotateType,
  type FeedItemPostType,
  type ImageType,
  type GalleryImageType,
} from "@fwa/src/types";
import { useOptimizelyContext } from "@fwa/src/contexts/OptimizelyContext";
// dynamic imports
const FeedItemShareModal = dynamic(
  () => import("@fwa/src/components/FeedItemShareModal"),
);

type Props = {
  feedItem?: FeedItemPostType;
  status: FeedItemStatusType;
  displayName: string;
  avatarUrl?: string;
  handleCancelClick?: () => void;
  /** handleEditData is used to update and mutate feeditem data */
  handleEditData?: (data: Partial<FeedItemPostType>) => void;
  /** handleCreate is NOT used to update and mutate feeditem data, that has already happened, but the page data like feed item totals, due to a poorly designed API */
  handleCreate?: (data: FeedItemPostType) => void;
  pageId?: string;
  parentPageLink?: ReactNode;
};

export const FeedItemPostForm = ({
  feedItem,
  avatarUrl,
  displayName,
  handleEditData,
  handleCreate,
  pageId,
  handleCancelClick,
  status,
  parentPageLink,
}: Props) => {
  const galleryImage: GalleryImageType | undefined = feedItem?.galleryImages
    .length
    ? feedItem.galleryImages[0]
    : undefined;
  const image: ImageType | undefined = galleryImage?.image;

  const { trackError, trackEventGtm } = useTracking();
  const { trackEventOptimizely } = useOptimizelyContext();
  const [fileState, setFileState] = useState<Blob | null>(null);
  const [rotation, setRotation] = useState<number>(0);
  const [imageDeleteClicked, setImageDeleteClicked] = useState<boolean>(false);
  const [lastCreatedFeedItem, setLastCreatedFeedItem] =
    useState<FeedItemPostType | null>(null);
  const [bodyState, setBodyState] = useState(feedItem?.body || "");
  const [imageUrl, setImageUrl] = useState<string | null>(
    feedItem?.galleryImages[0]?.image?.filePath
      ? imagePathFromImageType(feedItem.galleryImages[0].image)
      : null,
  );
  const [imageModalIsVisible, setImageModalIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Error messages from post submission or image rotation
  const [error, setError] = useState<Error | null>(null);
  // Validation messages are visible on the child input
  const [validationMessage, setValidationMessage] = useState<string>("");
  const hasNoContent = bodyState.length === 0 && !fileState && !imageUrl;
  const rotate =
    rotation % 360 === 0 ? 0 : (((rotation % 360) + 360) as RotateType);
  const timeString = feedItem?.created
    ? `${formatDistanceToNow(new Date(feedItem.created), {
        addSuffix: true,
        includeSeconds: true,
      })}`
    : "Right Now";

  // cancel on escape key
  useKey(
    () => {
      if (handleCancelClick) handleCancelClick();
    },
    {
      detectKeys: ["Escape"],
    },
    [],
  );

  const handleSubmit = () => {
    setIsLoading(true);
    setError(null);

    const submitAction = status === "creating" ? doCreate : doEdit;

    submitAction()
      .then(() => {
        setIsLoading(false);
        if (status === "creating") {
          //  reset everything on success
          clearAllFields();
        }
        return undefined;
      })
      .catch(() => {
        setIsLoading(false);
        setError(new Error("Unable to submit post"));
      });
  };

  const handleImageRotate = () => {
    setRotation((rotation || 0) - 90);
  };

  const clearAllFields = () => {
    setBodyState("");
    handleImageRemove();
    setImageDeleteClicked(false);
  };

  const handleImageRemove = () => {
    setError(null);
    setFileState(null);
    setImageUrl(null);
    setImageModalIsVisible(false);
    setRotation(0);
    setImageDeleteClicked(true);
  };

  const doEdit = async () => {
    if (!feedItem) return;
    const bodyChanged = bodyState !== feedItem.body;
    let editedPost: FeedItemPostType = feedItem;

    // delete old image if need
    if (imageDeleteClicked && galleryImage) {
      await fetcher(
        fwsUrlGalleryImage({ galleryImageUniqueId: galleryImage.uniqueId }),
        {
          method: "DELETE",
        },
      ).then(() => {
        setImageDeleteClicked(false);
        return undefined;
      });

      editedPost.galleryImages = [];
    }

    // if image added add image to post
    if (fileState) {
      // create post with image
      const galleryImageForFeedItem: GalleryImageType | void = await fetcher(
        fwsUrlPostImage({ postId: feedItem.uniqueId }),
        {
          headers: {
            "Content-Type": fileState.type || "image/jpeg ",
          },
          method: "POST",
          body: fileState,
        },
      )
        .then((res) => res as GalleryImageType)
        .catch((err) => {
          trackError(err as Error, { component: "FeedItemPostForm" });
          setError(new Error("unable to create post with image"));
        });

      editedPost = {
        ...editedPost,
        galleryImages: [
          ...(galleryImageForFeedItem ? [galleryImageForFeedItem] : []),
        ],
      };
    }

    // update text if changed
    if (bodyChanged) {
      const feedItemWithText: FeedItemPostType | void = await fetcher(
        fwsUrlPost({ postId: feedItem.uniqueId }),
        {
          method: "PATCH",
          body: JSON.stringify({ body: bodyState }),
        },
      )
        .then((res) => res as FeedItemPostType)
        .catch((err) => {
          trackError(err as Error, { component: "FeedItemPostForm" });
          setError(new Error("unable to update text on post with image"));
        });

      if (feedItemWithText) {
        editedPost = feedItemWithText;
      }
    }

    // if image rotated update image
    if (rotate) {
      const rotatedImage: ImageType | void = await updateImage({
        imageId: editedPost.galleryImages[0].image.uniqueId,
        rotate,
      }).catch((err) => {
        trackError(err as Error, { component: "FeedItemPostForm" });
        setError(new Error("unable to rotate image"));
      });

      if (rotatedImage) {
        editedPost = {
          ...editedPost,
          galleryImages: [
            {
              ...editedPost.galleryImages[0],
              image: {
                ...editedPost.galleryImages[0].image,
                url: rotatedImage.url,
                filePath: rotatedImage.filePath,
              },
            },
          ],
        };
      }
    }

    // do local mutation
    if (handleEditData) handleEditData(editedPost);
  };

  const doCreate = async () => {
    if (hasNoContent) {
      setError(new Error("A message or image is required"));
      return null;
    }

    if (!pageId) {
      const err = new Error("Page ID not known");
      trackError(err, { component: "FeedItemPostForm", function: "doCreate" });
      setError(err);
      return null;
    }

    let newPost: FeedItemPostType | undefined;

    // if fileState create post with image binary
    if (fileState) {
      const postWithImage: FeedItemPostType | void = await fetcher(
        fwsUrlNewPostWithImage({ pageId }),
        {
          headers: {
            "Content-Type": fileState.type || "image/jpeg ",
          },
          method: "POST",
          body: fileState,
        },
      )
        .then((res) => res as FeedItemPostType)
        .catch((err) => {
          trackError(err as Error, {
            component: "FeedItemPostForm",
            function: "fwsUrlNewPostWithImage",
          });
          setError(new Error("unable create post with image"));
        });

      if (postWithImage) {
        newPost = postWithImage;

        // update post with text if there is any
        if (bodyState.length) {
          const postWithBody: FeedItemPostType | void = await fetcher(
            fwsUrlPost({ postId: newPost.uniqueId }),
            {
              method: "PATCH",
              body: JSON.stringify({ body: bodyState }),
            },
          )
            .then((res) => res as FeedItemPostType)
            .catch((err) => {
              trackError(err as Error, {
                component: "FeedItemPostForm",
                function: "fwsUrlPost",
              });
              setError(
                new Error("unable to update text for new post with image"),
              );
            });
          if (postWithBody) {
            newPost = postWithBody;
          }
        }

        // rotate image in post if need
        if (rotate !== 0) {
          const rotatedImage: ImageType | void = await updateImage({
            imageId: newPost.galleryImages[0].image.uniqueId,
            rotate,
          }).catch((err) => {
            trackError(err as Error, {
              component: "FeedItemPostForm",
              function: "updateImage",
            });
            setError(new Error("unable to rotate image on new post"));
          });

          if (rotatedImage) {
            const newPostWithUpdatedImage: FeedItemPostType = {
              ...newPost,
              galleryImages: [
                {
                  ...newPost.galleryImages[0],
                  image: {
                    ...newPost.galleryImages[0].image,
                    url: rotatedImage.url,
                    filePath: rotatedImage.filePath,
                  },
                },
              ],
            };

            newPost = newPostWithUpdatedImage;
          }
        }
      }
    } else if (!fileState && bodyState.length) {
      // if we only have a text body and no image file then create a new post with the text
      const newPostWithText: FeedItemPostType | void = await fetcher(
        fwsUrlNewPost({ pageId }),
        {
          method: "POST",
          body: JSON.stringify({ body: bodyState }),
        },
      )
        .then((res) => res as FeedItemPostType)
        .catch((err) => {
          trackError(err as Error, {
            component: "FeedItemPostForm",
            function: "fwsUrlNewPost",
          });
          setError(new Error("unable to create text post"));
        });

      if (newPostWithText) {
        newPost = newPostWithText;
      }
    }

    //  finally do tracking, sharing and local mutation
    if (newPost) {
      if (handleCreate) handleCreate(newPost);
      // to trigger share prompt modal
      setLastCreatedFeedItem(newPost);
      trackEventGtm({
        event: "feeditem_add",
        entityType: "Post",
      });
      trackEventOptimizely({
        eventKey: "of_feeditem_post_create",
      });
    }
    return null;
  };

  return (
    <div data-component="feed-item-post-form">
      <Box marginBottom="xs">
        <UserBlock
          name={displayName}
          avatarUrl={avatarUrl}
          size="m"
          extra={
            <>
              {parentPageLink && <Text>Posted to {parentPageLink}</Text>}
              {status !== "creating" && (
                <Text as="span" textColor="currentColor">
                  <IconFa faIcon={faClock} /> {timeString}
                </Text>
              )}
            </>
          }
        />
      </Box>
      {status === "editing" || status === "creating" ? (
        // ////////////////////////// Edit / Create state /////////////////////
        <form noValidate>
          <EditableTextAreaField
            name="post body"
            text={bodyState}
            aria-label={
              status === "creating" ? "Create a post" : "Edit your post"
            }
            setCurrentValue={(value) => {
              setBodyState(value);
            }}
            validation={{
              type: "textField",
              maxLength: 1000,
            }}
            setValidationMessage={setValidationMessage}
            lineCount={4}
          />
          {!imageUrl ? (
            <ImageUploader
              id={`imageUploadPostForm${status}`}
              renderDragArea={
                <Text textAlign="left">
                  <SpanVerticalSpacer>
                    <IconFa faIcon={faCamera} />
                  </SpanVerticalSpacer>
                  Add a photo
                </Text>
              }
              onImageUpload={({
                error: uploadError,
                image: uploadImage,
                file,
              }) => {
                if (uploadError) {
                  setError(new Error("unable to upload image"));
                }
                if (!uploadError && uploadImage && file) {
                  setError(null);
                  setFileState(file);
                  setImageUrl(uploadImage.localUrl);
                }
              }}
            />
          ) : (
            <Box>
              <UploadedImageWrapper>
                <Box>
                  <GalleryThumbnail
                    rotation={rotation as RotateType}
                    image={{ url: imageUrl }}
                  />
                </Box>
                <RowStretch>
                  <Button
                    type="button"
                    aria-label="image rotate left"
                    onClick={handleImageRotate}
                    data-cta-type="image-rotate"
                  >
                    <IconFa faIcon={faRotateLeft} />
                  </Button>
                  <Button
                    type="button"
                    aria-label="remove image"
                    onClick={handleImageRemove}
                  >
                    <IconFa faIcon={faTrashCan} />
                  </Button>
                </RowStretch>
              </UploadedImageWrapper>
            </Box>
          )}
          {!!error?.message && <ErrorText>{error.message}</ErrorText>}
          <FormActions
            isLoading={isLoading}
            isValid={!validationMessage.length}
            submitLabel={status !== "creating" ? "Save" : "Add update"}
            onCancel={
              status !== "creating" && !!handleCancelClick
                ? handleCancelClick
                : null
            }
            onSubmit={() => {
              handleSubmit();
            }}
            submitButtonType="button"
          />
        </form>
      ) : (
        // ////////////////////////// View state /////////////////////
        <div>
          {feedItem?.body && <ReadMore>{feedItem?.body}</ReadMore>}
          {image && feedItem?.galleryImages[0] && (
            <>
              <ImageWrapper>
                <GalleryImageClickable
                  galleryImage={feedItem?.galleryImages[0]}
                  onClick={() => {
                    setImageModalIsVisible(true);
                  }}
                  aria-label="enlarge gallery image"
                  // typically we'd have an aria controls here as well
                  // however the modal has no content other than an image so we're only adding the label for gtm
                />
              </ImageWrapper>
              {imageModalIsVisible ? (
                <Modal
                  showCloseButton
                  modalName="gallery image details"
                  closeFunction={() => {
                    setImageModalIsVisible(false);
                    if (handleCancelClick) handleCancelClick();
                  }}
                >
                  <Box marginTop="xl">
                    <GalleryThumbnail image={image} />
                  </Box>
                </Modal>
              ) : null}
            </>
          )}
        </div>
      )}
      {/* SHARE MODAL START */}
      {lastCreatedFeedItem ? (
        <FeedItemShareModal
          lastCreatedFeedItem={lastCreatedFeedItem}
          setLastCreatedFeedItem={setLastCreatedFeedItem}
        />
      ) : null}
      {/* SHARE MODAL END  */}
    </div>
  );
};

export default memo(FeedItemPostForm);
